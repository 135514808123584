import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "./../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

import ic_delete from "../assets/images/ic_delete.svg";
import logodeeniyat from "../assets/images/logo_big.png";
import ic_edit from "../assets/images/ic_edit.svg";
import ic_eyes from "../assets/images/ic_eyes.svg";
import Modal from "react-bootstrap/Modal";
import {
  DeleteCallWithErrorResponse,
  multipartPostCallWithErrorResponse,
  PostCallWithAuthTokenWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../api/ApiServices";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import ApiConfig, { FILE_BASE_URL } from "../api/ApiConfig";
import { ToastMsg } from "./TostMsg";
import { Pagination } from "./Pagination";
import FormLoader from "./FormLoader";
const DuaSunnat = ({ key }) => {

  let module_id;
  let course_yr_id;
  let category_id;

  if (typeof window !== undefined) {
    module_id = localStorage.getItem("module_id");
    course_yr_id = localStorage.getItem("course_yr_id");
    category_id = localStorage.getItem("category_id");
  }

  const { sidebar, adminData } = useContext(AppContext);
  const [loader, setLoader] = useState(true);
  const [duaaList, setDuaaList] = useState([]);
  const [idEdit, setIdEdit] = useState("");
  const [viewDetails, setViewDetails] = useState([]);
  const [imageWidth, setImageWidth] = useState({
    sabaqAudio: "100%",
    Uploade: "100%",
    Tarjama: "100%",
    Description: "100%",
  });

  const [addNamaz, setAddNamaz] = useState({
    sabaqName: "",
    sabaqAudio: "",
    sabaqIcon: [],
    sabaqImagePath: "",
    subSabaqs: [
      {
        subSabaqTitle:"",
        subSabaqTitleAudio:"",
        subSabaqDesc: "",
        descAudioPath: "",
        imagePath: "",
        tarjama: "",
        tarjamaAudioPath: "",
        descReference: "",

      },
    ],
  });


  const [add, setAdd] = useState(false);
  const handleAddClose = () => {
    setAddNamaz({
      sabaqName: "",
      sabaqAudio: "",
      sabaqIcon: [],
      sabaqImagePath: "",
      subSabaqs: [
        {
          subSabaqTitle:"",
          subSabaqDesc: "",
          descAudioPath: "",
          imagePath: "",
          tarjama: "",
          tarjamaAudioPath: "",
          descReference: "",
        },
      ],
    });
    setAdd(false);
    setIdEdit("");
  };
  const handleAddShow = () => setAdd(true);
  const [lasatSabaqId, setLsatSabaq] = useState("");
  const [saveBotton, setSaveBotton] = useState(false);

  // console.log(lasatSabaqId);
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const handleDeleteShow = () => setDelete(true);

  const [viewModal, setView] = useState(false);
  const handleViewClose = () => setView(false);
  const handleViewShow = () => setView(true);
  const [AddNewGroup, setAddNewGroup] = useState(0);

  const [validated, setValidated] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const startIndex = currentPage * 15;
  const endIndex = startIndex + 15;
  const changePage = ({ selected }) => {
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(duaaList?.length / 15);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      addDuaaApi();
    }

    setValidated(true);
  };
  //ad
  const addDuaaApi = () => {
    setSaveBotton(true);
    handleAddClose();
    setLoader(true);

    const body = {
      moduleId: module_id,
      courseYrId: (module_id === 1 || module_id === 2) && course_yr_id,
      categoryId: 3,
      userId: Number(adminData.user_id),
      sabaqNo: lasatSabaqId + 1,
      sabaqName: addNamaz.sabaqName ? addNamaz.sabaqName : "",
      sabaqId: addNamaz.SabaqId ? addNamaz.SabaqId : "",
      sabaqIcon: addNamaz.sabaqIcon,
    };
    //all dataa paylodade

    let payLoadFormData = new FormData();
    payLoadFormData.append("moduleId", body.moduleId);
    payLoadFormData.append("courseYrId", course_yr_id);
    payLoadFormData.append("categoryId", body.categoryId);
    payLoadFormData.append("userId", body.userId);
    payLoadFormData.append("sabaqNo", body.sabaqNo);
    payLoadFormData.append("sabaqIcon", body.sabaqIcon ? body.sabaqIcon : "");
    idEdit === "edit"
      ? payLoadFormData.append("sabaqId", body.sabaqId)
      : payLoadFormData.append("sabaqId", body.sabaqId);
    payLoadFormData.append("sabaqName", body.sabaqName);
    let subSabaqs = addNamaz.subSabaqs.map((ele, index) => {
      return {
        subSabaqTitle:ele?.subSabaqTitle ? ele?.subSabaqTitle : "",
        subSabaqTitleAudio:ele?.subSabaqTitleAudio == "" ? "no file" : "file name",
        subSabaqDescReference: ele?.descReference ? ele?.descReference : "",
        subSabaqDesc: ele?.subSabaqDesc ? ele.subSabaqDesc : "",
        subSabaqNo: index + 1,
        subSabaqTarjamaAudio:
          ele?.tarjamaAudioPath == "" ? "no file" : "file name",
        subSabaqDescAudio: ele?.descAudioPath == "" ? "no file" : "file name",
        subSabaqImgVdo: ele?.imagePath == "" ? "no file" : "file name",
        subSabaqTarjama: ele?.tarjama ? ele?.tarjama : "",
      };
    });

    let subSabaqsEdit = addNamaz?.subSabaqs.map((ele, index) => {
      return {
        
        subSabaqId: ele?.subSabaqId,
        subSabaqTitle: ele?.subSabaqTitle ? ele?.subSabaqTitle : "",
        subSabaqDescReference: ele?.descReference ? ele?.descReference : "",
        subSabaqDesc: ele?.subSabaqDesc ? ele?.subSabaqDesc : "",
        subSabaqNo: index + 1,
        subSabaqTarjama: ele?.tarjama ? ele?.tarjama : "",
        subSabaqTitleAudio: typeof ele?.subSabaqTitleAudio == "object" ?"updated" : "not updated",
        subSabaqTarjamaAudio:
          typeof ele?.tarjamaAudioPath == "object" ? "updated" : "not updated",
        subSabaqImgVdo:
          typeof ele?.imagePath == "object" ? "updated" : "not updated",
        subSabaqDescAudio:
          typeof ele?.descAudioPath == "object" ? "updated" : "not updated",

      };

    });

    idEdit === "edit"
      ? payLoadFormData.append("subSabaq", JSON.stringify(subSabaqsEdit))
      : payLoadFormData.append("subSabaq", JSON.stringify(subSabaqs));

    let newSubaqAudio = [];
    newSubaqAudio.push(addNamaz.sabaqAudio);
    newSubaqAudio.map((ele) =>
      payLoadFormData.append("sabaqNameAudio", ele ? ele : "")
    );

    let newImage = [];
    newImage.push(addNamaz?.sabaqImagePath);
    newImage.map((ele) => payLoadFormData.append("sabaqImage", ele ? ele : ""));
    
    addNamaz?.subSabaqs.map((ele) =>
      payLoadFormData.append(
        "subSabaqDescAudio",
        ele?.descAudioPath ? ele?.descAudioPath : ""
      )
    );
    
    addNamaz.subSabaqs.map((ele) =>
      payLoadFormData.append(
        "subSabaqTarjamaAudio",
        ele?.tarjamaAudioPath ? ele?.tarjamaAudioPath : ""
      )
    );

    addNamaz.subSabaqs.map((ele) =>
    payLoadFormData.append(
      "subSabaqTitleAudio",
      ele?.subSabaqTitleAudio ? ele?.subSabaqTitleAudio : ""
    )
  );



    // addNamaz.subSabaqs.map((ele) =>
    //   payLoadFormData.append(
    //     "subSabaqImgVdo",
    //     ele?.imagePath ? ele?.imagePath : ""
    //   )
    // );

    let newSubSabaqImages = [];
    addNamaz?.subSabaqs.map((ele) =>
      newSubSabaqImages.push(ele?.imagePath ? ele?.imagePath : "")
    );

    newSubSabaqImages.map((ele) =>
      payLoadFormData.append("subSabaqImgVdo", ele ? ele : "")
    );

    setLoader(true);

    idEdit === "edit"
      ? putMultipartWithAuthCallWithErrorResponse(
        ApiConfig.UPDATE_SABAQ_SECTION,
        payLoadFormData
      )
        .then((res) => {
          if (res.json && res.json?.success === true) {
            ToastMsg("success", res.json.message);
            duaaListApi();
            setSaveBotton(false);
          } else {
            ToastMsg("error", res.json.message);
          }
        })
        .catch((err) => {
          setLoader(false);

          ToastMsg("error", "something went wrong");
        })
      : multipartPostCallWithErrorResponse(
        ApiConfig.ADD_SABAQ_SECTION,
        payLoadFormData
        // payLoadFormDataNew
      )
        .then((res) => {
          if (res.json && res.json?.success === true) {
            ToastMsg("success", res.json.message);
            duaaListApi();
            setSaveBotton(false);
          } else {
            ToastMsg("error", res.json.message);
          }
        })
        .catch((err) => {
          setLoader(false);

          ToastMsg("error", "something went wrong");
        });
  };
  //
  useEffect(() => {
    duaaListApi();
  }, [key]);
  const duaaListApi = () => {
    const apiUrl = (module_id == 1 || module_id == 2)
      ? ApiConfig.GET_ALL_SABAQ + `moduleId=${module_id}&courseYrId=${course_yr_id}&categoryId=${3}`
      : ApiConfig.GET_ALL_SABAQ + `moduleId=${module_id}&categoryId=${3}`;
    simpleGetCallWithErrorResponse(apiUrl)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setDuaaList(res.json.data.allSabaq);

          setLsatSabaq(res.json?.data.allSabaq.length);
        } else {
          // ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        // ToastMsg("error", "something went wrong");
      });
  };
  //
  const EditListApi = (id) => {
    setLoader(true);
    simpleGetCallWithErrorResponse(ApiConfig.VIEW_ALL_SABAQ + `sabaqId=${id}&categoryId=${category_id}`)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setAddNamaz(res.json.data);
          handleAddShow();
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };
  const duaaLisDeleteApi = () => {
    setLoader(true);
    const body = {
      categoryId: 3,
      sabaqId: deleteId,
    };

    DeleteCallWithErrorResponse(
      ApiConfig.DELETE_SABAQ_SECION,

      JSON.stringify({
        ...body,
      })
    )
      .then((res) => {
        setLoader(false);
        handleDeleteClose();
        if (res.json && res.json?.success === true) {
          duaaListApi();
          ToastMsg("success", res.json.message);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  //view the
  const viewDetailshandle = (id) => {
    simpleGetCallWithErrorResponse(ApiConfig.VIEW_SABAQ_SECTION + `sabaqId=${id}&categoryId=${category_id}`)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setViewDetails(res.json.data);
          handleViewShow();
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };
  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Dua Sunnat</label>
        </div>
        <div className="add-btn">
          <button onClick={handleAddShow}>
            Add Sabaq
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
            </svg>
          </button>
        </div>
      </div>
      {/* ======================= START ADD ========================== */}
      <Modal
        show={add}
        onHide={handleAddClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {idEdit === "edit" ? "Update  Dua Sunnat" : "Add Dua Sunnat"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form Validate validated={validated} className="fw-bold" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12 mb-3">
                <Form.Label>Sabaq Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={addNamaz?.sabaqName}
                  onChange={(e) =>
                    setAddNamaz({ ...addNamaz, sabaqName: e.target.value })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Sabaq Name
                </Form.Control.Feedback>
              </div>

              {idEdit ? (
                <>
                  <div className="row d-flex">
                    <div className="col-md-12">
                      <label htmlFor="" className="key">
                        Sabaq Audio
                      </label>
                    </div>
                    <div
                      className={imageWidth === "100%" ? "d-none" : "col-md-6 "}
                    >
                      <Form.Label>
                        {typeof addNamaz?.sabaqAudio === "string"
                          ? addNamaz?.sabaqAudio.substring(
                            addNamaz?.sabaqAudio.length - 15,
                            addNamaz?.sabaqAudio.length
                          )
                          : ""}
                      </Form.Label>
                    </div>
                    <div className="col-md-12 mb-3 ">
                      <Form.Control
                        type="file"
                        accept="audio/mp3,audio/*;capture=microphone"
                        name="sabaqAudio"
                        style={{ width: imageWidth.sabaqAudio }}
                        onChange={(e) => {
                          setImageWidth({ ...imageWidth, sabaqAudio: "100%" });
                          setAddNamaz({
                            ...addNamaz,
                            sabaqAudio: e.target.files[0],
                          });
                        }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-md-12 mb-3">
                  <Form.Label>Sabaq Audio</Form.Label>
                  <Form.Control
                    type="file"
                    accept="audio/mp3,audio/*;capture=microphone"
                    name="sabaqAudio"
                    onChange={(e) =>
                      setAddNamaz({
                        ...addNamaz,
                        sabaqAudio: e.target.files[0],
                      })
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Name
                  </Form.Control.Feedback>
                </div>
              )}

              {idEdit ? (
                <>
                  <div className="row d-flex">
                    <div className="col-md-12">
                      <label htmlFor="" className="key">
                        Sabaq Image
                      </label>
                    </div>
                    <div
                      className={imageWidth === "100%" ? "d-none" : "col-md-6 "}
                    >
                      <Form.Label>
                        {typeof addNamaz?.sabaqImagePath === "string"
                          ? addNamaz?.sabaqImagePath.substring(
                            addNamaz?.sabaqImagePath.length - 15,
                            addNamaz?.sabaqImagePath.length
                          )
                          : ""}
                      </Form.Label>
                    </div>
                    <div className="col-md-12 mb-3 ">
                      <Form.Control
                        type="file"
                        name="sabaqImagePath"
                        accept="image/x-png,image/gif,image/jpeg"
                        style={{ width: imageWidth.Uploade }}
                        onChange={(e) => {
                          setImageWidth({ ...imageWidth, Uploade: "100%" });
                          setAddNamaz({
                            ...addNamaz,
                            sabaqImagePath: e.target.files[0],
                          });
                        }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-md-12 mb-3">
                  <Form.Label>Sabaq Image</Form.Label>
                  <Form.Control
                    type="file"
                    name="sabaqImagePath"
                    accept="image/x-png,image/gif,image/jpeg"
                    onChange={(e) =>
                      setAddNamaz({
                        ...addNamaz,
                        sabaqImagePath: e.target.files[0],
                      })
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Name
                  </Form.Control.Feedback>
                </div>
              )}
 
     {/* subqIcon */}
              {/*{idEdit ? (
                <div>
                  <label>Sabaq Icon</label>
                  <MDBRow>
                    <MDBCol lg="1" md="1" className="mb-4">
                      <img
                        src={
                          !addNamaz?.sabaqIcon
                            ? ""
                            : ApiConfig.FILE_BASE_URL + addNamaz?.sabaqIcon.length
                              ? ApiConfig.FILE_BASE_URL + addNamaz?.sabaqIcon
                              : ApiConfig.FILE_BASE_URL + addNamaz?.sabaqIcon &&
                              URL.createObjectURL(ApiConfig.FILE_BASE_URL + addNamaz?.sabaqIcon)

                        }
                        className="img-fluid rounded value"
                        alt=""
                      />
                    </MDBCol>
                  </MDBRow>
                  <div className="col-md-12 mb-3">
                    <Form.Label>Update Sabaq Icon</Form.Label>
                    <Form.Control
                      type="file"
                      accept="image/*"
                      name="updateSabaqIcon"
                      onChange={(e) => {
                        setAddNamaz({
                          ...addNamaz,
                          sabaqIcon: e.target.files[0],
                        });
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Sabaq Icon
                    </Form.Control.Feedback>
                  </div>
                </div>
              ) : (
                <div className="col-md-12 mb-3">
                  <Form.Label>Sabaq Icon</Form.Label>
                  <Form.Control
                    // required
                    type="file"
                    // accept="audio/mp3,audio/*;capture=microphone"
                    accept="image/*"
                    name="sabaqAudio"
                    src={addNamaz?.sabaqIcon}
                    //  / value={addNamaz?.sabaqAudio}
                    onChange={(e) => {
                      setAddNamaz({
                        ...addNamaz,
                        sabaqIcon: e.target.files[0],
                      });
                    }}
                  />

                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Icon
                  </Form.Control.Feedback>
                </div>
              )}
*/}

              {addNamaz?.subSabaqs &&
                addNamaz?.subSabaqs.map((elementInArray, index) => {
                  // console.log("elementInArray", elementInArray);
console.log("subsaubtitle",elementInArray?.subSabaqTitle)
                  return (
                    <div
                      className="col-md-12 sub-group-main"
                      key={"duaa" + index}
                    >
                      <div className="row">
                        <h6 className="fw-bold">Sub Sabaq Details</h6>

                        <h6>SubSubaq No {index + 1}</h6>
                        <div className="col-md-12 mb-3">
                          <Form.Label> Sub Sabaq Title</Form.Label>
                          <Form.Control
                            type="text"
                            rows={3}
                            value={elementInArray?.subSabaqTitle}
                            onChange={(e) => {
                              setAddNamaz({
                                ...addNamaz,
                                subSabaqs: addNamaz.subSabaqs.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                        ...item,
                                        subSabaqTitle: e.target.value,
                                      }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Aqaaid Description.
                          </Form.Control.Feedback>
                        </div>
                        {idEdit ? (
                          <>
                            <div className="row d-flex">
                              <div className="col-md-12">
                                <label htmlFor="" className="key">
                                  Sub Sabaq title Audio
                                </label>
                              </div>
                              <div
                                className={
                                  imageWidth === "100%" ? "d-none" : "col-md-6 "
                                }
                              >
                                <Form.Label>
                                  {typeof elementInArray?.titleAudioPath ===
                                    "string"
                                    ? elementInArray?.titleAudioPath.substring(
                                      elementInArray?.titleAudioPath.length -
                                      15,
                                      elementInArray?.titleAudioPath.length
                                    )
                                    : ""}
                                </Form.Label>
                              
                              </div>
                              <div className="col-md-12 mb-3 ">
                                <Form.Control
                                  type="file"
                                  accept="audio/mp3,audio/*;capture=microphone"
                                  name="subSabaqTitleAudio"
                                  style={{ width: imageWidth.Tarjama }}
                                  onChange={(e) => {
                                    setImageWidth({
                                      ...imageWidth,
                                      Tarjama: "100%",
                                    });
                                    setAddNamaz({
                                      ...addNamaz,
                                      subSabaqs: addNamaz.subSabaqs.map(
                                        (item, innerIndex) => {
                                          return index === innerIndex
                                            ? {
                                              ...item,
                                              subSabaqTitleAudio:
                                                e.target.files[0],
                                            }
                                            : item;
                                        }
                                      ),
                                    });
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Sabaq Name
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-3">
                            <Form.Label> Sub Sabaq title Audio</Form.Label>
                            <Form.Control
                              type="file"
                              accept="audio/mp3,audio/*;capture=microphone"
                              name="subSabaqTitleAudio"
                              onChange={(e) => {
                                setAddNamaz({
                                  ...addNamaz,
                                  subSabaqs: addNamaz.subSabaqs.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                          ...item,
                                          subSabaqTitleAudio: e.target.files[0],
                                        }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div>
                        )}
                        <div className="col-md-12 mb-3">
                          <Form.Label> Sub Sabaq Description</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={elementInArray?.subSabaqDesc}
                            onChange={(e) => {
                              setAddNamaz({
                                ...addNamaz,
                                subSabaqs: addNamaz.subSabaqs.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                        ...item,
                                        subSabaqDesc: e.target.value,
                                      }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Aqaaid Description.
                          </Form.Control.Feedback>
                        </div>

                        {idEdit ? (
                          <>
                            <div className="row d-flex">
                              <div className="col-md-12">
                                <label htmlFor="" className="key">
                                  Sub Sabaq Description Audio
                                </label>
                              </div>
                              <div
                                className={
                                  imageWidth === "100%" ? "d-none" : "col-md-6 "
                                }
                              >
                                <Form.Label>
                                  {typeof elementInArray?.descAudioPath ===
                                    "string"
                                    ? elementInArray?.descAudioPath.substring(
                                      elementInArray?.descAudioPath.length -
                                      15,
                                      elementInArray?.descAudioPath.length
                                    )
                                    : ""}
                                </Form.Label>
                              </div>
                              <div className="col-md-12 mb-3 ">
                                <Form.Control
                                  type="file"
                                  accept="audio/mp3,audio/*;capture=microphone"
                                  name="sabaqImagePath"
                                  style={{ width: imageWidth.Description }}
                                  onChange={(e) => {
                                    setImageWidth({
                                      ...imageWidth,
                                      Description: "100%",
                                    });
                                    setAddNamaz({
                                      ...addNamaz,
                                      subSabaqs: addNamaz.subSabaqs.map(
                                        (item, innerIndex) => {
                                          return index === innerIndex
                                            ? {
                                              ...item,
                                              descAudioPath:
                                                e.target.files[0],
                                            }
                                            : item;
                                        }
                                      ),
                                    });
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Sabaq Name
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-3">
                            <Form.Label>Sub Sabaq Description Audio</Form.Label>
                            <Form.Control
                              type="file"
                              accept="audio/mp3,audio/*;capture=microphone"
                              name="sabaqImagePath"
                              onChange={(e) => {
                                setAddNamaz({
                                  ...addNamaz,
                                  subSabaqs: addNamaz.subSabaqs.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                          ...item,
                                          descAudioPath: e.target.files[0],
                                        }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div>
                        )}
                        <div className="col-md-12 mb-3">
                          <Form.Label>Sub Sabaq Reference</Form.Label>
                          <Form.Control
                            type="text"
                            value={elementInArray?.descReference}
                            onChange={(e) => {
                              setAddNamaz({
                                ...addNamaz,
                                subSabaqs: addNamaz.subSabaqs.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                        ...item,
                                        descReference: e.target.value,
                                      }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Reference
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-12 mb-3">
                          <Form.Label> Sub Sabaq Tarjama</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={elementInArray?.tarjama}
                            onChange={(e) => {
                              setAddNamaz({
                                ...addNamaz,
                                subSabaqs: addNamaz.subSabaqs.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                        ...item,
                                        tarjama: e.target.value,
                                      }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Tarjama.
                          </Form.Control.Feedback>
                        </div>

                        {idEdit ? (
                          <>
                            <div className="row d-flex">
                              <div className="col-md-12">
                                <label htmlFor="" className="key">
                                  Sub Sabaq Tarjama Audio
                                </label>
                              </div>
                              <div
                                className={
                                  imageWidth === "100%" ? "d-none" : "col-md-6 "
                                }
                              >
                                <Form.Label>
                                  {typeof elementInArray?.tarjamaAudioPath ===
                                    "string"
                                    ? elementInArray?.tarjamaAudioPath.substring(
                                      elementInArray?.tarjamaAudioPath
                                        .length - 15,
                                      elementInArray?.tarjamaAudioPath.length
                                    )
                                    : ""}
                                </Form.Label>
                              </div>
                              <div className="col-md-12 mb-3 ">
                                <Form.Control
                                  type="file"
                                  accept="audio/mp3,audio/*;capture=microphone"
                                  name="sabaqImagePath"
                                  style={{ width: imageWidth.Tarjama }}
                                  onChange={(e) => {
                                    setImageWidth({
                                      ...imageWidth,
                                      Tarjama: "100%",
                                    });
                                    setAddNamaz({
                                      ...addNamaz,
                                      subSabaqs: addNamaz.subSabaqs.map(
                                        (item, innerIndex) => {
                                          return index === innerIndex
                                            ? {
                                              ...item,
                                              tarjamaAudioPath:
                                                e.target.files[0],
                                            }
                                            : item;
                                        }
                                      ),
                                    });
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Sabaq Name
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-3">
                            <Form.Label> Sub Sabaq Tarjama Audio</Form.Label>
                            <Form.Control
                              type="file"
                              accept="audio/mp3,audio/*;capture=microphone"
                              name="tarjamaAudioPath"
                              onChange={(e) => {
                                setAddNamaz({
                                  ...addNamaz,
                                  subSabaqs: addNamaz.subSabaqs.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                          ...item,
                                          tarjamaAudioPath: e.target.files[0],
                                        }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div>
                        )}
                      </div>
                      {idEdit ? (
                        <>
                          <div className="row d-flex">
                            <div className="col-md-12">
                              <label htmlFor="" className="key">
                                Sub Sabaq Image
                              </label>
                            </div>
                            <div
                              className={imageWidth === "100%" ? "d-none" : "col-md-6 "}
                            >
                              <Form.Label>
                                {typeof elementInArray?.imagePath ===
                                  "string"
                                  ? elementInArray?.imagePath.substring(
                                    elementInArray?.imagePath
                                      .length - 15,
                                    elementInArray?.imagePath.length
                                  )
                                  : ""}
                              </Form.Label>
                            </div>
                            <div className="col-md-12 mb-3 ">
                              <Form.Control
                                type="file"
                                name="imagePath"
                                accept="image/x-png,image/gif,image/jpeg"
                                style={{ width: imageWidth?.Uploade }}

                                onChange={(e) => {
                                  setImageWidth({
                                    ...imageWidth,
                                    Uploade: "100%",
                                  });
                                  setAddNamaz({
                                    ...addNamaz,
                                    subSabaqs: addNamaz.subSabaqs.map(
                                      (item, innerIndex) => {
                                        return index === innerIndex
                                          ? {
                                            ...item,
                                            imagePath: e.target.files[0],
                                          }
                                          : item;
                                      }
                                    ),
                                  });
                                }}
                                // onChange={(e) => {
                                //   setImageWidth({
                                //     ...imageWidth,
                                //     Uploade: "100%",
                                //   });
                                //   setAddNamaz({
                                //     ...addNamaz,
                                //     subSabaqs: addNamaz.subSabaqs.map(
                                //       (item, innerIndex) => {
                                //         return index === innerIndex
                                //           ? {
                                //             ...item,
                                //             imagePath: e.target.files[0],
                                //           }
                                //           : item;
                                //       }
                                //     ),
                                //   });
                                //   //   subSabaqs: addNamaz?.subSabaqs?.map(
                                //   //     (item, innerIndex) => {
                                //   //       return index === innerIndex
                                //   //         ? {
                                //   //           ...item,
                                //   //           imagePath:
                                //   //             e.target.files[0],
                                //   //         }
                                //   //         : item;
                                //   //     }
                                //   //   ),
                                //   // });
                                // }}
                              />

                              <Form.Control.Feedback type="invalid">
                                Please Enter Sabaq Name
                              </Form.Control.Feedback>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="col-md-12 mb-3">
                          <Form.Label> Sub Sabaq Image</Form.Label>
                          <Form.Control
                            type="file"
                            name="imagePath"
                            accept="image/x-png,image/gif,image/jpeg"
                            onChange={(e) => {
                              setAddNamaz({
                                ...addNamaz,
                                subSabaqs: addNamaz.subSabaqs.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                        ...item,
                                        imagePath: e.target.files[0],
                                      }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />

                          <Form.Control.Feedback type="invalid">
                            Please Enter Sabaq Name
                          </Form.Control.Feedback>
                        </div>
                      )}
                      <div className="text-end">
                        <Link
                          to="#"
                          className={
                            addNamaz.subSabaqs.length > 1
                              ? "btn btn-danger"
                              : "btn btn-secondary invisible"
                          }
                          onClick={() => {
                            if (addNamaz.subSabaqs.length > 1) {
                              const copySubDeatails = [...addNamaz.subSabaqs];
                              copySubDeatails.splice(index, 1);
                              setAddNamaz({
                                ...addNamaz,
                                subSabaqs: copySubDeatails,
                              });
                            }
                          }}
                        >
                          Remove
                        </Link>
                      </div>
                    </div>
                  );
                })}

              <Link
                to="#"
                className={"add-newSabaq-btn btn btn-primary"}
                onClick={() => {
                  let namaz = [
                    ...addNamaz.subSabaqs,

                    {
                      subSabaqDesc: "",
                      descAudioPath: "",
                      imagePath: "",
                      tarjama: "",
                      tarjamaAudioPath: "",
                      descReference: "",
                    },
                  ];
                  setAddNamaz({
                    ...addNamaz,
                    subSabaqs: namaz,
                  });
                }}
              >
                + Add Sub Sabaq Details
              </Link>
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <button className="me-2" type="submit" disabled={saveBotton}>
                {idEdit === "edit" ? "update" : "Save"}
              </button>
              <Link
                to="#"
                className=" bg-danger"
                onClick={() => {
                  setAddNamaz({
                    sabaqName: "",
                    sabaqAudio: "",
                    sabaqImagePath: "",
                    subSabaqs: [
                      {
                        subSabaqDesc: "",
                        descAudioPath: "",
                        imagePath: "",
                        tarjama: "",
                        tarjamaAudioPath: "",
                        subSabaqsDescReference: "",
                      },
                    ],
                  });
                  handleAddClose();
                  setIdEdit("");
                }}
              >
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END ADD ========================== */}

      {/* ======================= DELETE ========================== */}

      <Modal
        show={deleteModal}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              type="submit"
              onClick={() => {
                handleDeleteClose()
                duaaLisDeleteApi()
              }}
            >
              Delete
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
      {/* ======================= END DELETE ========================== */}

      {/* ======================= VIEW ========================== */}
      <Modal
        show={viewModal}
        onHide={handleViewClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Dua Sunnat Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Sabaq No.
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewDetails?.SabaqNo}
                  </label>
                </div>
              </div>
           {/*sabaqIcon  */}
              <div className="col-md-6 mb-3">
                {/* <div>
                  <label htmlFor="" className="key">
                    Sabaq Icon
                  </label>
                </div>
                <div>
                  <MDBRow>
                    <MDBCol lg="2" md="3" className="mb-4">
                      <img
                        src={
                          !viewDetails?.sabaqIcon
                            ? ""
                            : ApiConfig.FILE_BASE_URL +
                              viewDetails?.sabaqIcon.length
                              ? ApiConfig.FILE_BASE_URL + viewDetails?.sabaqIcon
                              : ApiConfig.FILE_BASE_URL +
                              viewDetails?.sabaqIcon &&
                              URL.createObjectURL(
                                ApiConfig.FILE_BASE_URL +
                                viewDetails?.sabaqIcon
                              )
                        }
                        className="img-fluid rounded value"
                        alt=""
                      />
                    </MDBCol>
                  </MDBRow>
                </div> */}
              </div>

              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Sabaq Name
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewDetails?.sabaqName}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="mb-2">
                  <label htmlFor="" className="key">
                    Sabaq Audio
                  </label>
                </div>
                <div>
                  {viewDetails?.sabaqAudio?(
                        <audio controls style={{ width: "100%", height: "38px" }}>
                        <source src={`${FILE_BASE_URL}${viewDetails?.sabaqAudio}`} type="audio/ogg" />
                        <source src={`${FILE_BASE_URL}${viewDetails?.sabaqAudio}`} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                  ):(
                   ""
                  )}
              
                </div>
              </div>

              <div className="col-md-12 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Sabaq Image
                  </label>
                </div>
                <div>
                  <MDBRow>
                    <MDBCol lg="1" md="2" className="mb-4">
                      <img
                        src={
                          !viewDetails?.sabaqImagePath
                            ? ""
                            : ApiConfig.FILE_BASE_URL + viewDetails?.sabaqImagePath.length
                              ? ApiConfig.FILE_BASE_URL + viewDetails?.sabaqImagePath
                              : ApiConfig.FILE_BASE_URL + viewDetails?.sabaqImagePath &&
                              URL.createObjectURL(ApiConfig.FILE_BASE_URL + viewDetails?.sabaqImagePath)
                        }
                        className="img-fluid rounded value"
                        alt=""
                      />
                    </MDBCol>
                  </MDBRow>
                </div>
              </div>
              <h5>sub sabaq details</h5>
              {viewDetails.subSabaqs &&
                viewDetails.subSabaqs.length > 0 &&
                viewDetails.subSabaqs.map((ele, index) => {
                  return (
                    <div
                      className="sub-group-main row mb-3"
                      key={"testkey" + index}
                    >
                      <div className="col-md-12 mb-3">
                        <div>
                          <label htmlFor="" className="key fw-bold mb-4">
                            Sabaq No {ele.subSabaqNo}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3" key={"duaaV" + index}>
                        <div>
                          <label htmlFor="" className="key">
                            Sub Sabaq Title
                          </label>
                        </div>
                        <div>
                          <label htmlFor="" className="value">
                            {ele.subSabaqTitle}
                          </label>
                        </div>
                      </div>
                  
                  
                      <div className="col-md-6 mb-3">
                        <div className="mb-2">
                          <label htmlFor="" className="key">
                            Sub Sabaq title Audio
                          </label>
                        </div>
                        <div>
                          {
                            ele?.titleAudioPath?(
                              <audio
                              controls
                              style={{ width: "100%", height: "38px" }}
                            >
                              <source src={`${FILE_BASE_URL}${ele.titleAudioPath}`} type="audio/ogg" />
                              <source src={`${FILE_BASE_URL}${ele.titleAudioPath}`} type="audio/mpeg" />
                              Your browser does not support the audio element.
                            </audio>
                            ):(
                            ""
                            )
                          }
                    
                        </div>
                      </div>

                      
                     <div className="col-md-6 mb-3" key={"duaaV" + index}>
                        <div>
                          <label htmlFor="" className="key">
                            Sub Sabaq Description
                          </label>
                        </div>
                        <div>
                          <label htmlFor="" className="value">
                            {ele.subSabaqDesc}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="mb-2">
                          <label htmlFor="" className="key">
                            Sub Sabaq Description Audio
                          </label>
                        </div>
                        <div>
                          {
                            ele?.descAudioPath?(
                              <audio
                              controls
                              style={{ width: "100%", height: "38px" }}
                            >
                              <source src={`${FILE_BASE_URL}${ele.descAudioPath}`} type="audio/ogg" />
                              <source src={`${FILE_BASE_URL}${ele.descAudioPath}`} type="audio/mpeg" />
                              Your browser does not support the audio element.
                            </audio>
                            ):(
                            ""
                            )
                          }
                    
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div>
                          <label htmlFor="" className="key">
                            Sub Sabaq Reference
                          </label>
                        </div>
                        <div>
                          <label htmlFor="" className="value">
                            {ele.descReference}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div>
                          <label htmlFor="" className="key">
                            Sub Sabaq Tarjama
                          </label>
                        </div>
                        <div>
                          <label htmlFor="" className="value">
                            {ele.tarjama}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="mb-2">
                          <label htmlFor="" className="key">
                            Sub Sabaq Tarjama Audio
                          </label>
                        </div>
                        <div>
                          {ele?.tarjamaAudioPath?(
                               <audio
                               controls
                               style={{ width: "100%", height: "38px" }}
                             >
                               <source
                                 src={`${FILE_BASE_URL}${ele.tarjamaAudioPath}`}
                                 type="audio/ogg"
                               />
                               <source
                                 src={`${FILE_BASE_URL}${ele.tarjamaAudioPath}`}
                                 type="audio/mpeg"
                               />
                               Your browser does not support the audio element.
                             </audio>
                          ):(
                           ""
                          )}
                       
                        </div>
                      </div>
                      {/* SubSubSabaq Image */}
                      <div className="col-md-12 mb-3">
                        <div>
                          <label htmlFor="" className="key">
                            Sub Sabaq Image
                          </label>
                        </div>
                        <div>
                          <MDBRow>
                            <MDBCol lg="1" md="2" className="mb-4 mt-2">
                              <img
                                src={
                                  !ele?.imagePath
                                    ? ""
                                    : ApiConfig.FILE_BASE_URL + ele.imagePath.length
                                      ? ApiConfig.FILE_BASE_URL + ele.imagePath
                                      : ApiConfig.FILE_BASE_URL + ele.imagePath &&
                                      URL.createObjectURL(ApiConfig.FILE_BASE_URL + ele.imagePath)
                                }
                                className="img-fluid rounded value "
                                alt=""
                              />
                            </MDBCol>
                          </MDBRow>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className="bg-danger" onClick={handleViewClose}>
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END VIEW ========================== */}
      {loader ? (
        <FormLoader />
      ) : (
        <div className="table-wrapper">
          <h6>Total Records : {duaaList.length}</h6>
          <Table className="display" striped bordered hover size="lg">
            <thead className="text-center">
              <tr>
                <th>Sabaq No.</th>
                <th
                // style={{ textAlign: "left" }}
                >Sabaq List</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {duaaList && duaaList.length > 0
                ? duaaList.slice(startIndex, endIndex).map((ele, index) => {
                  return (
                    <tr key={"duaaL" + index}>
                      <td>{index + 1 + startIndex}</td>

                      <td style={{ textAlign: "left" }}>{ele.sabaqName}</td>
                      <td className="action">
                        <Link
                          to="#"
                          className="icon edit"
                          onClick={() => {
                            // handleAddShow();
                            setIdEdit("edit");
                            setImageWidth({
                              ...imageWidth,
                              sabaqAudio: "117px",
                              Uploade: "117px",
                              Tarjama: "117px",
                              Description: "117px",
                            });
                            EditListApi(ele.sabaqId);
                          }}
                        >
                          <img src={ic_edit} alt="" />
                        </Link>
                        <Link
                          to="#"
                          className="icon view"
                          onClick={() => viewDetailshandle(ele.sabaqId)}
                        >
                          <img src={ic_eyes} alt="" />
                        </Link>
                        <Link
                          to="#"
                          className="icon delete"
                          onClick={() => {
                            handleDeleteShow();
                            setDeleteId(ele.sabaqId);
                          }}
                        >
                          <img src={ic_delete} alt="" />
                        </Link>
                      </td>
                    </tr>
                  );
                })
                : ""}
            </tbody>
          </Table>
          {duaaList && duaaList.length > 0 ? (
            <Pagination
              changePage={changePage}
              pageCount={pageCount}
              currentPage={currentPage}
            />
          ) : (
            <div>
              <h5 style={{ color: "red", textAlign: "center" }}>
                {" "}
                Records Not Found
              </h5>
            </div>
          )}
        </div>
      )}
    </main>
  );
};

export default DuaSunnat;
